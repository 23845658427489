@import './fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Nominie:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Nominee;
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/Nominee-Regular_8cad7736.woff2') format('woff2'), url('./assets/fonts/Nominee-Bold_4746af54.woff2') format('woff2'), url('./assets/fonts/Nominee-ExtraBold_08d10d76.woff2') format('woff2'), url('./assets/fonts/Nominee-Medium_70a8d0b5.woff2') format('woff2'); font-display: swap

}

@font-face {
  font-family: 'Nominie';
  src: url('./assets/fonts/Nominee-Regular_8cad7736.woff2') format('woff2'), url('./assets/fonts/Nominee-Bold_4746af54.woff2') format('woff2'), url('./assets/fonts/Nominee-ExtraBold_08d10d76.woff2') format('woff2'), url('./assets/fonts/Nominee-Medium_70a8d0b5.woff2') format('woff2'); font-display: swap;

  font-weight: normal;
  font-style: normal;
  font-display: swap
}



html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Nominie', sans-serif;
  /* font-family: 'Helvetica Neue', sans-serif; */
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0.5rem;

}


/* Hide horizontal scrollbar */ 
 ::-webkit-scrollbar-horizontal {
  display: none;
} 

/* Style the remaining vertical scrollbar track and thumb (optional) */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
} 